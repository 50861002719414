import { ProjectChainData } from '@/data/ProjectChainData'
import React from 'react'

export default function BroadcastIcon() {
  return (
    <svg viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7719 11.4386C1.52068 21.6898 1.52068 38.3104 11.7719 48.5617C12.2601 49.0498 12.2601 49.8413 11.7719 50.3294C11.2838 50.8176 10.4923 50.8176 10.0042 50.3294C-1.2234 39.1019 -1.2234 20.8984 10.0042 9.6708C10.4923 9.18265 11.2838 9.18265 11.7719 9.6708C12.2601 10.159 12.2601 10.9504 11.7719 11.4386ZM48.895 9.6708C49.3832 9.18265 50.1747 9.18265 50.6628 9.6708C61.8904 20.8984 61.8904 39.1019 50.6628 50.3294C50.1747 50.8176 49.3832 50.8176 48.895 50.3294C48.4069 49.8413 48.4069 49.0498 48.895 48.5617C59.1463 38.3104 59.1463 21.6898 48.895 11.4386C48.4069 10.9504 48.4069 10.159 48.895 9.6708ZM30.3336 23.7501C26.8818 23.7501 24.0836 26.5483 24.0836 30.0001C24.0836 33.4519 26.8818 36.2501 30.3336 36.2501C33.7854 36.2501 36.5836 33.4519 36.5836 30.0001C36.5836 26.5483 33.7854 23.7501 30.3336 23.7501ZM21.5836 30.0001C21.5836 25.1676 25.5011 21.2501 30.3336 21.2501C35.1661 21.2501 39.0836 25.1676 39.0836 30.0001C39.0836 34.8326 35.1661 38.7501 30.3336 38.7501C25.5011 38.7501 21.5836 34.8326 21.5836 30.0001ZM18.8433 41.4906C12.4973 35.1446 12.4973 24.8557 18.8433 18.5096C19.3315 18.0215 19.3315 17.23 18.8433 16.7419C18.3552 16.2537 17.5637 16.2537 17.0756 16.7419C9.75323 24.0642 9.75323 35.936 17.0756 43.2584C17.5637 43.7465 18.3552 43.7465 18.8433 43.2584C19.3315 42.7702 19.3315 41.9788 18.8433 41.4906ZM43.5921 16.7419C43.1039 16.2537 42.3125 16.2537 41.8243 16.7419C41.3361 17.23 41.3361 18.0215 41.8243 18.5096C48.1703 24.8557 48.1703 35.1446 41.8243 41.4906C41.3361 41.9788 41.3361 42.7702 41.8243 43.2584C42.3125 43.7465 43.1039 43.7465 43.5921 43.2584C50.9144 35.936 50.9144 24.0642 43.5921 16.7419Z"
        fill={ProjectChainData.CHAIN_PRIMARY_COLOR}
      />
    </svg>
  )
}
