import BroadcastIcon from '@/assets/BroadcastIcon'
import MonitorSCIcon from '@/assets/MonitorSCIcon'
import WalletIcon from '@/assets/WalletIcon'
import { ReactComponent as ArbitrumLogo } from '@/assets/arbitrum_alerts_logo.svg'
import { ReactComponent as ArchwayLogo } from '@/assets/archway-alerts-logo.svg'
import { ReactComponent as ArchwayLogoMobile } from '@/assets/icons/archway-alerts-mobile.svg'
import { ReactComponent as ArbitrumOneLogo } from '@/assets/icons/OneChainLogo.svg'
import { ReactComponent as ArbitrumOneLogoDisabled } from '@/assets/icons/OneChainLogoDisabled.svg'
import { ReactComponent as ArbitrumNovaLogo } from '@/assets/icons/NovaChainLogo.svg'
import { ReactComponent as ArbitrumNovaLogoDisabled } from '@/assets/icons/NovaChainLogoDisabled.svg'
import { ReactComponent as TestnetArbitrumChainLogo } from '@/assets/icons/TestnetArbitrumChainLogo.svg'
import { ReactComponent as TestnetArbitrumChainLogoDisabled } from '@/assets/icons/TestnetArbitrumChainLogoDisabled.svg'

export type AlertTypeProps = {
  title: string
  description: string
  icon: JSX.Element
  name: string
  alertType: string
}

const getAlertTypes = (chainName: string): AlertTypeProps[] => {
  let alerts = []
  alerts.push(
    {
      title: 'Monitor Wallet',
      description: 'Keep a close eye on the account activity',
      icon: <WalletIcon />,
      name: '1',
      alertType: 'MONITOR_WALLET',
    },
    {
      title: 'Monitor Smart Contract',
      description: 'Track smart contract events in real time',
      icon: <MonitorSCIcon />,
      name: '2',
      alertType: 'MONITOR_SMART_CONTRACT',
    },
    {
      title: `${chainName} Broadcast`,
      description: `Notifications from the ${chainName} Foundation`,
      icon: <BroadcastIcon />,
      name: '2',
      alertType: 'BROADCAST',
    }
  )
  return alerts
}

export const ProjectChainData = getChainData()

function getChainData() {
  switch (process.env.CHAIN_NAME) {
    case 'arbitrum':
      return {
        AlertTypes: getAlertTypes('Arbitrum'),
        CHAIN_NAME: 'Arbitrum',
        CHAIN_TITLE_NAME: 'Arbitrum Alerts',
        CHAIN_TYPE: 'EVM_CHAIN',
        CHAIN_FAVICON: '/arbitrum-favicon.ico',
        CHAIN_LOGO: <ArbitrumLogo />,
        CHAIN_LOGO_MOBILE: <ArbitrumLogo />,
        CHAIN_NETWORKS: [
          {
            NETWORK: {
              NETWORK_LOGO: <ArbitrumOneLogo />,
              NETWORK_LOGO_DISABLED: <ArbitrumOneLogoDisabled />,
              NETWORK_NAME: 'One',
              NETWORK_ENDPOINT: 'https://www.arbitrum-alerts.com',
            },
          },
          {
            NETWORK: {
              NETWORK_LOGO: <ArbitrumNovaLogo />,
              NETWORK_LOGO_DISABLED: <ArbitrumNovaLogoDisabled />,
              NETWORK_NAME: 'Nova',
              NETWORK_ENDPOINT: 'https://nova.arbitrum-alerts.com',
            },
          },
          {
            NETWORK: {
              NETWORK_LOGO: <TestnetArbitrumChainLogo />,
              NETWORK_LOGO_DISABLED: <TestnetArbitrumChainLogoDisabled />,
              NETWORK_NAME: 'Testnet',
              NETWORK_ENDPOINT: 'https://testnet.arbitrum-alerts.com',
            },
          },
        ],
        MAINNET_CHAIN_ICON: <ArbitrumOneLogo />,
        MAINNET_CHAIN_ICON_DISABLED: <ArbitrumOneLogoDisabled />,
        TESTNET_CHAIN_ICON: <ArbitrumNovaLogo />,
        TESTNET_CHAIN_ICON_DISABLED: <ArbitrumNovaLogoDisabled />,
        CHAIN_PRIMARY_COLOR: '#32AAFF',
        CHAIN_INPUT_PLACEHOLDER: 'Arbitrum contract address',
        BUTTON_BORDER_COLOR: '#32AAFF',
        BUTTON_COLOR_WL: '#32AAFF',
        BUTTON_HOVER_COLOR_WL: '#32AAFF3D',
        SELECTED_ALERT_BACKGROUND_COLOR: '#32AAFF3D',
        SELECTED_ALERT_CHECK_COLOR: '#32AAFF',
        BACKGROUND_COLOR: '#213147',
        INPUT_BORDER_COLOR: '#32AAFF',
        MODAL_BG_COLOR: '#3C4A5D',
        CHAIN_FONT: 'Inter',
        CHAIN_FONT_TITLE: 'conthrax-sb',
        TITLE_SIZE: '35px',
        TITLE_SIZE_MOBILE: '32px',
        TITLE_SIZE_CREATE_ALERT: '35px',
        TITLE_SIZE_CREATE_ALERT_MOBILE: '22px',
        IS_TITLE_UPPERCASE: true,
        MAINNET_CHAIN_NAME: 'One',
        TESTNET_CHAIN_NAME: 'Nova',
        CHAIN_MAINNET_ENDPPOINT: 'https://www.arbitrum-alerts.com',
        CHAIN_TESTNET_ENDPPOINT: 'https://testnet.arbitrum-alerts.com',
      }
    case 'archway':
      return {
        AlertTypes: getAlertTypes('Archway'),
        CHAIN_NAME: 'Archway',
        CHAIN_TITLE_NAME: 'Archway Alerts',
        CHAIN_TYPE: 'COSMOS_CHAIN',
        CHAIN_FAVICON: '/archway-favicon.ico',
        CHAIN_LOGO: <ArchwayLogo />,
        CHAIN_LOGO_MOBILE: <ArchwayLogoMobile />,
        CHAIN_NETWORKS: [
          {
            NETWORK: {
              NETWORK_LOGO: '',
              NETWORK_LOGO_DISABLED: '',
              NETWORK_NAME: 'Mainnet',
              NETWORK_ENDPOINT: 'https://www.archway-alerts.com',
            },
          },
          {
            NETWORK: {
              NETWORK_LOGO: '',
              NETWORK_LOGO_DISABLED: '',
              NETWORK_NAME: 'Testnet',
              NETWORK_ENDPOINT: 'https://testnet.archway-alerts.com',
            },
          },
        ],
        CHAIN_PRIMARY_COLOR: '#FF4D00',
        CHAIN_INPUT_PLACEHOLDER: 'Archway contract address',
        BUTTON_BORDER_COLOR: '#FF4D00',
        BUTTON_COLOR_WL: '#FF4D00',
        BUTTON_HOVER_COLOR_WL: '#d94200',
        SELECTED_ALERT_BACKGROUND_COLOR: '#FF4D003D',
        SELECTED_ALERT_CHECK_COLOR: '#FF4D00',
        BACKGROUND_COLOR: 'black',
        INPUT_BORDER_COLOR: '#FF4D00',
        MODAL_BG_COLOR: '#353535',
        CHAIN_FONT: 'TWK Everett',
        CHAIN_FONT_TITLE: 'TWK Everett',
        TITLE_SIZE: '50px',
        TITLE_SIZE_MOBILE: '32px',
        TITLE_SIZE_CREATE_ALERT: '62px',
        TITLE_SIZE_CREATE_ALERT_MOBILE: '30px',
        IS_TITLE_UPPERCASE: false,
        MAINNET_CHAIN_NAME: 'Mainnet',
        TESTNET_CHAIN_NAME: 'Testnet',
        CHAIN_MAINNET_ENDPPOINT: 'https://www.archway-alerts.com',
        CHAIN_TESTNET_ENDPPOINT: 'https://testnet.archway-alerts.com',
      }
    default:
      return {
        AlertTypes: getAlertTypes('Archway'),
        CHAIN_NAME: 'Archway',
        CHAIN_TITLE_NAME: 'Archway Alerts',
        CHAIN_TYPE: 'COSMOS_CHAIN',
        CHAIN_FAVICON: '/archway-favicon.ico',
        CHAIN_LOGO: <ArchwayLogo />,
        CHAIN_LOGO_MOBILE: <ArchwayLogoMobile />,
        CHAIN_NETWORKS: [
          {
            NETWORK: {
              NETWORK_LOGO: '',
              NETWORK_LOGO_DISABLED: '',
              NETWORK_NAME: 'Mainnet',
              NETWORK_ENDPOINT: 'https://www.archway-alerts.com',
            },
          },
          {
            NETWORK: {
              NETWORK_LOGO: '',
              NETWORK_LOGO_DISABLED: '',
              NETWORK_NAME: 'Testnet',
              NETWORK_ENDPOINT: 'https://testnet.archway-alerts.com',
            },
          },
        ],
        CHAIN_PRIMARY_COLOR: '#FF4D00',
        CHAIN_INPUT_PLACEHOLDER: 'Archway contract address',
        BUTTON_BORDER_COLOR: '#FF4D00',
        BUTTON_COLOR_WL: '#FF4D00',
        BUTTON_HOVER_COLOR_WL: '#d94200',
        SELECTED_ALERT_BACKGROUND_COLOR: '#FF4D003D',
        SELECTED_ALERT_CHECK_COLOR: '#FF4D00',
        BACKGROUND_COLOR: 'black',
        INPUT_BORDER_COLOR: '#FF4D00',
        MODAL_BG_COLOR: '#353535',
        CHAIN_FONT: 'TWK Everett',
        CHAIN_FONT_TITLE: 'TWK Everett',
        TITLE_SIZE: '50px',
        TITLE_SIZE_MOBILE: '32px',
        TITLE_SIZE_CREATE_ALERT: '62px',
        TITLE_SIZE_CREATE_ALERT_MOBILE: '30px',
        IS_TITLE_UPPERCASE: false,
        MAINNET_CHAIN_NAME: 'Mainnet',
        TESTNET_CHAIN_NAME: 'Testnet',
        CHAIN_MAINNET_ENDPPOINT: 'https://www.archway-alerts.com',
        CHAIN_TESTNET_ENDPPOINT: 'https://testnet.archway-alerts.com',
      }
  }
}
