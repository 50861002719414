import jwt from 'jwt-decode'

interface DecodedToken {
  sub: string
  exp: number
}

export enum TokenKey {
  ACCESSTOKEN = 'accessToken',
}

class AuthService {
  public decodeToken(token: string): DecodedToken | undefined {
    return jwt(token)
  }
}
export const authService = new AuthService()
