import * as Sentry from '@sentry/nextjs'
import { DestinationType } from '@/common/types/common.types'
import {
  Alert,
  AlertCosmosMonitorFunds,
  AlertCosmosSmartContractEvents,
  AlertEthMonitorFunds,
  AlertEthSmartContractEvents,
  BroadcastAlert,
  ConfirmDestinationRegistrationRequest,
  ConfirmDestinationRegistrationResponse,
  CosmosAlert,
  CreateAlertRequest,
  CreateAlertResponse,
  DeleteAlertRequest,
  DeleteAlertResponse,
  DeleteUserDestinationRequest,
  DeleteUserDestinationResponse,
  Destination,
  EthAlert,
  GetAlertsRequest,
  GetAlertsResponse,
  GetChainsRequest,
  GetChainsResponse,
  GetJwtRequest,
  GetJwtResponse,
  GetNotificationsRequest,
  GetNotificationsResponse,
  GetStatisticsRequest,
  GetStatisticsResponse,
  GetUserDestinationsRequest,
  GetUserDestinationsResponse,
  InitiateDestinationRegistrationRequest,
  InitiateDestinationRegistrationResponse,
  UpdateAlertRequest,
  UserAlert,
} from '@/proto/api_pb'
import localforage from 'localforage'
import { GatewayClient, GatewayPublicClient } from '../proto/ApiServiceClientPb'
import { authService } from './AuthService'
import { ProjectChainData } from '@/data/ProjectChainData'

export type SmartContractEvent = {
  eventName: string
  eventValue: string
}

type AlertProps = {
  alertType: string
  setAddress: string
  setFrom: string
  setTo: string
  chainId: string
  destinationId?: string
  scEventName: string
  smartContractEvents: SmartContractEvent[]
  contractAbi: string
}

type ErrorType = {
  status: string
  data: any
}

type SuccessType = {
  status: string
  data: any
}

const apiService = new GatewayClient(process.env.API_URL as string)
const publicApiService = new GatewayPublicClient(process.env.API_URL as string)

class UserService {
  // --------------------------------- ALERT ---------------------------------
  // --------------------------------- ALERT ---------------------------------
  async createAlert({
    alertType,
    setAddress,
    setFrom,
    setTo,
    chainId,
    destinationId,
    smartContractEvents,
    scEventName,
    contractAbi,
  }: AlertProps): Promise<SuccessType | ErrorType> {
    const mempoolsToken = await localforage.getItem(process.env.COOKIE_NETWORK_KEY as string)
    const userId = authService.decodeToken(mempoolsToken as string)?.sub

    if (!userId) {
      return { status: 'FAIL', data: 'No user ID' }
    }

    try {
      const createAlertRequest: CreateAlertRequest = new CreateAlertRequest()
      createAlertRequest.setUserId(userId)

      if (!destinationId) return { status: 'FAIL', data: 'No destination ID' }

      createAlertRequest.addDestinationIds(destinationId)

      createAlertRequest.setChainId('1')

      const alert = new Alert()

      switch (ProjectChainData.CHAIN_TYPE) {
        case 'COSMOS_CHAIN': {
          const cosmosAlert = new CosmosAlert()
          const broadcastAlert = new BroadcastAlert()
          if (alertType === 'MONITOR_WALLET') {
            const msgAlertMonitorFunds = new AlertCosmosMonitorFunds()
            msgAlertMonitorFunds.setAddress(setAddress)
            cosmosAlert.setAlertCosmosMonitorFunds(msgAlertMonitorFunds)
            alert.setCosmosAlert(cosmosAlert)
          } else if (alertType === 'MONITOR_SMART_CONTRACT') {
            const msgAlertMonitorSmartContract = new AlertCosmosSmartContractEvents()
            msgAlertMonitorSmartContract.setAddress(setAddress)
            msgAlertMonitorSmartContract.setEventName(scEventName)
            smartContractEvents.forEach((event: SmartContractEvent) => {
              // if (event.eventName != '' && event.eventValue != '') {
              msgAlertMonitorSmartContract
                .getEventAttributesMap()
                .set(event.eventName, event.eventValue)
              // }
            })
            cosmosAlert.setAlertCosmosSmartContractEvents(msgAlertMonitorSmartContract)
            alert.setCosmosAlert(cosmosAlert)
          } else if (alertType === 'BROADCAST') {
            alert.setBroadcastAlert(broadcastAlert)
          }
          break
        }
        case 'EVM_CHAIN': {
          const evmAlert = new EthAlert()
          const broadcastAlert = new BroadcastAlert()
          if (alertType === 'MONITOR_WALLET') {
            const msgAlertMonitorFunds = new AlertEthMonitorFunds()
            msgAlertMonitorFunds.setAddress(setAddress)
            evmAlert.setAlertEthMonitorFunds(msgAlertMonitorFunds)
            alert.setEthAlert(evmAlert)
          } else if (alertType === 'MONITOR_SMART_CONTRACT') {
            const msgAlertMonitorSmartContract = new AlertEthSmartContractEvents()
            msgAlertMonitorSmartContract.setContractAddr(setAddress)
            msgAlertMonitorSmartContract.setContractAbi(contractAbi)
            msgAlertMonitorSmartContract.setEventName(scEventName)
            smartContractEvents.forEach((event: SmartContractEvent) => {
              if (event.eventName != '' && event.eventValue != '') {
                msgAlertMonitorSmartContract
                  .getEventAttributesMap()
                  .set(event.eventName, event.eventValue)
              }
            })
            evmAlert.setAlertEthSmartContractEvents(msgAlertMonitorSmartContract)
            alert.setEthAlert(evmAlert)
          } else if (alertType === 'BROADCAST') {
            alert.setBroadcastAlert(broadcastAlert)
          }
          break
        }
      }

      createAlertRequest.setAlert(alert)

      const response: CreateAlertResponse = await apiService.createAlert(createAlertRequest, {
        Authorization: 'Bearer ' + mempoolsToken,
      })

      console.log('Alert created successfully')
      return { status: 'SUCCESS', data: response.toObject() }
    } catch (error) {
      Sentry.captureException(error);
      return { status: 'FAIL', data: error }
    }
  }

  async updateAlert(alertProps: AlertProps): Promise<SuccessType | ErrorType> {
    try {
      const updateAlert: UpdateAlertRequest = new UpdateAlertRequest()
      const userAlert: UserAlert = new UserAlert()

      if (alertProps) {
        // userAlert.setId(alertProps.alertId)
        // userAlert.setName(alertProps.alertName)
        // userAlert.setDestinationIdsList(alertProps.destinationIds)
        // alertProps.alertStatus && userAlert.setStatus(alertProps.alertStatus)

        // userAlert.setChainId(alertProps.chainId)
        // const alert: Alert = buildAlert(
        //   alertProps.chainType,
        //   alertProps.alertType,
        //   alertProps.setAddress,
        //   alertProps.setTo,
        //   alertProps.setFrom,
        //   alertProps.outcome,
        //   alertProps.contractAbi,
        //   alertProps.scEventName,
        //   alertProps.smartContractEvents
        // )
        // userAlert.setAlert(alert)

        updateAlert.setAlert(userAlert)

        const response = await apiService.updateAlert(updateAlert, {
          // Authorization: 'Bearer ' + userToken,
        })

        return { status: 'SUCCESS', data: response.toObject() }
      }
      return { status: 'FAIL', data: 'No Alert ID' }
    } catch (error) {
      Sentry.captureException(error);
      return { status: 'FAIL', data: error }
    }
  }

  async setAlertStatus(
    alertId: string,
    status: UserAlert.Status,
    destinations: string[]
  ): Promise<SuccessType | ErrorType> {
    const mempoolsToken = await localforage.getItem(process.env.COOKIE_NETWORK_KEY as string)

    try {
      const updateAlert: UpdateAlertRequest = new UpdateAlertRequest()
      const userAlert: UserAlert = new UserAlert()

      userAlert.setId(alertId)
      userAlert.setDestinationIdsList(destinations)

      userAlert.setStatus(status)
      updateAlert.setAlert(userAlert)

      const response = await apiService.updateAlert(updateAlert, {
        Authorization: 'Bearer ' + mempoolsToken,
      })

      return { status: 'SUCCESS', data: response.toObject() }
    } catch (error) {
      Sentry.captureException(error);
      return { status: 'FAIL', data: error }
    }
  }

  async getAlertsByUser(page?: number): Promise<SuccessType | ErrorType> {
    const mempoolsToken = await localforage.getItem(process.env.COOKIE_NETWORK_KEY as string)

    const userId = authService.decodeToken(mempoolsToken as string)?.sub
    try {
      const getAlertsByUserRequest: GetAlertsRequest = new GetAlertsRequest()
      getAlertsByUserRequest.setUserId(userId || '0')
      getAlertsByUserRequest.setChainId('1')
      page && getAlertsByUserRequest.setPage(page)

      const response: GetAlertsResponse = await apiService.getAlerts(getAlertsByUserRequest, {
        Authorization: 'Bearer ' + mempoolsToken,
      })

      return { status: 'SUCCESS', data: response.toObject() }
    } catch (e) {
      Sentry.captureException(e);
      return { status: 'FAIL', data: e }
    }
  }

  async deleteAlert(alertId: string): Promise<SuccessType | ErrorType> {
    const mempoolsToken = await localforage.getItem(process.env.COOKIE_NETWORK_KEY as string)
    // const userToken = authService.get()

    try {
      const deleteAlertRequest: DeleteAlertRequest = new DeleteAlertRequest()
      deleteAlertRequest.setAlertId(alertId)

      const response: DeleteAlertResponse = await apiService.deleteAlert(deleteAlertRequest, {
        Authorization: 'Bearer ' + mempoolsToken,
      })

      return { status: 'SUCCESS', data: response.toObject() }
    } catch (e) {
      Sentry.captureException(e);
      return { status: 'FAIL', data: e }
    }
  }

  async createUserToken(): Promise<SuccessType | ErrorType> {
    try {
      const jwtRequest = new GetJwtRequest()
      const jwtToken: GetJwtResponse = await publicApiService.getJwt(jwtRequest, {})

      const jwtResponseToken = jwtToken.toObject()

      return { status: 'SUCCESS', data: jwtResponseToken.jwt }
    } catch (error) {
      Sentry.captureException(error);
      return { status: 'FAIL', data: error }
    }
  }

  // --------------------------------- DESTINATION ---------------------------------
  // --------------------------------- DESTINATION ---------------------------------
  async createDestination(
    destinationType: DestinationType,
    value: string
  ): Promise<SuccessType | ErrorType> {
    const mempoolsToken = await localforage.getItem(process.env.COOKIE_NETWORK_KEY as string)

    const userId = authService.decodeToken(mempoolsToken as string)?.sub

    try {
      const destination = new Destination()
      destinationType === 'EMAIL'
        ? destination.setEmail(value)
        : destinationType === 'WEBHOOK'
        ? destination.setWebhook(value)
        : destinationType === 'TELEGRAM'
        ? destination.setTelegram(value)
        : destination.setDiscordWebhook(value)

      const destinationRequest = new InitiateDestinationRegistrationRequest()
      destinationRequest.setUserId(userId || '0')
      destinationRequest.setDestination(destination)

      const response: InitiateDestinationRegistrationResponse =
        await apiService.initiateDestinationRegistration(destinationRequest, {
          Authorization: 'Bearer ' + mempoolsToken,
        })

      return { status: 'SUCCESS', data: response.toObject().destinationRegistrationProposalId }
    } catch (error) {
      Sentry.captureException(error);
      return { status: 'FAIL', data: error }
    }
  }

  async confirmDestination(destinationId: string, code: string): Promise<SuccessType | ErrorType> {
    const mempoolsToken = await localforage.getItem(process.env.COOKIE_NETWORK_KEY as string)

    try {
      const confirmDestinationRequest = new ConfirmDestinationRegistrationRequest()
      confirmDestinationRequest.setConfirmationCode(code)
      confirmDestinationRequest.setDestinationRegistrationProposalId(destinationId)

      const response: ConfirmDestinationRegistrationResponse =
        await apiService.confirmDestinationRegistration(confirmDestinationRequest, {
          Authorization: 'Bearer ' + mempoolsToken,
        })

      return { status: 'SUCCESS', data: response.toObject() }
    } catch (error) {
      Sentry.captureException(error);
      return { status: 'FAIL', data: error }
    }
  }

  async getUserDestinations(): Promise<SuccessType | ErrorType> {
    const mempoolsToken = await localforage.getItem(process.env.COOKIE_NETWORK_KEY as string)
    const userId = authService.decodeToken(mempoolsToken as string)?.sub
    try {
      const getUserDestination = new GetUserDestinationsRequest()
      getUserDestination.setUserId(userId || '0')

      const response: GetUserDestinationsResponse = await apiService.getUserDestinations(
        getUserDestination,
        {
          Authorization: 'Bearer ' + mempoolsToken,
        }
      )

      return { status: 'SUCCESS', data: response.toObject() }
    } catch (error) {
      Sentry.captureException(error);
      return { status: 'FAIL', data: error }
    }
  }

  async deleteDestination(destinationId: string): Promise<SuccessType | ErrorType> {
    const mempoolsToken = await localforage.getItem(process.env.COOKIE_NETWORK_KEY as string)
    try {
      const deleteUserDestination: DeleteUserDestinationRequest = new DeleteUserDestinationRequest()
      deleteUserDestination.setId(destinationId)

      const response: DeleteUserDestinationResponse = await apiService.deleteUserDestination(
        deleteUserDestination,
        {
          Authorization: 'Bearer ' + mempoolsToken,
        }
      )

      return { status: 'SUCCESS', data: response.toObject() }
    } catch (error) {
      Sentry.captureException(error);
      return { status: 'FAIL', data: error }
    }
  }

  // --------------------------------- NOTIFICATION ---------------------------------
  // --------------------------------- NOTIFICATION ---------------------------------
  async getNotificationByAlert(alertId: string): Promise<SuccessType | ErrorType> {
    const mempoolsToken = await localforage.getItem(process.env.COOKIE_NETWORK_KEY as string)

    try {
      const getNotificationByAlertRequest = new GetNotificationsRequest()
      getNotificationByAlertRequest.setAlertId(alertId)

      const response: GetNotificationsResponse = await apiService.getNotifications(
        getNotificationByAlertRequest,
        {
          Authorization: 'Bearer ' + mempoolsToken,
        }
      )

      return { status: 'SUCCESS', data: response.toObject() }
    } catch (error) {
      Sentry.captureException(error);
      return { status: 'FAIL', data: error }
    }
  }

  // --------------------------------- ALERT DATA ---------------------------------
  // --------------------------------- ALERT DATA ---------------------------------
  async getStatistics(alertId?: string): Promise<SuccessType | ErrorType> {
    const mempoolsToken = await localforage.getItem(process.env.COOKIE_NETWORK_KEY as string)

    try {
      const statisticsRequest = new GetStatisticsRequest()
      if (alertId) {
        statisticsRequest.setAlertId(alertId)
      }

      const response: GetStatisticsResponse = await apiService.getStatistics(statisticsRequest, {
        Authorization: 'Bearer ' + mempoolsToken,
      })

      return { status: 'SUCCESS', data: response.toObject() }
    } catch (error) {
      Sentry.captureException(error);
      return { status: 'FAIL', data: error }
    }
  }

  async getStatisticsByUser(): Promise<SuccessType | ErrorType> {
    const mempoolsToken = await localforage.getItem(process.env.COOKIE_NETWORK_KEY as string)

    try {
      const statisticsRequest = new GetStatisticsRequest()
      statisticsRequest.setUserId(authService.decodeToken(mempoolsToken as string)?.sub || '0')

      const response: GetStatisticsResponse = await apiService.getStatistics(statisticsRequest, {
        Authorization: 'Bearer ' + mempoolsToken,
      })
      return { status: 'SUCCESS', data: response.toObject() }
    } catch (error) {
      Sentry.captureException(error);
      return { status: 'FAIL', data: error }
    }
  }

  async getChain(): Promise<SuccessType | ErrorType> {
    const mempoolsToken = await localforage.getItem(process.env.COOKIE_NETWORK_KEY as string)
    try {
      const response: GetChainsResponse = await apiService.getChains(new GetChainsRequest(), {
        Authorization: 'Bearer ' + mempoolsToken,
      })
      return { status: 'SUCCESS', data: response.toObject() }
    } catch (error) {
      Sentry.captureException(error);
      return { status: 'FAIL', data: error }
    }
  }
}

export const userService = new UserService()
