import { userService } from '@/service/UserService'
import localforage from 'localforage'
import { useRouter } from 'next/router'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { AuthContextType } from './AuthContextType'
import LoadingScreen from '@/modules/loadingScreen/components/LoadingScreen'

interface Props {
  children: ReactNode
}

const AuthContext = createContext<AuthContextType | null>(null)
export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }: Props) => {
  const router = useRouter()
  const [user, setUser] = useState()
  const [loading, setLoading] = useState<boolean>(true)
  const [userToken, setUserToken] = useState<string>('')

  const saveToken = async () => {
    const mempoolsToken = await localforage.getItem(process.env.COOKIE_NETWORK_KEY as string)
    if (mempoolsToken !== null && typeof mempoolsToken === 'string') {
      setUserToken(mempoolsToken)
      setLoading(false)
      return mempoolsToken
    }

    const token = await userService.createUserToken()
    await localforage.setItem(process.env.COOKIE_NETWORK_KEY as string, token?.data)
    setUserToken(token.data)
    setLoading(false)
    return token
  }

  useEffect(() => {
    saveToken()
  }, [router])

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {loading ? <LoadingScreen /> : children}
    </AuthContext.Provider>
  )
}
