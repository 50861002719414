import { pxToRem } from "@/common/utils";
import { ProjectChainData } from "@/data/ProjectChainData";


export const typography = {
  fontFamily: [ProjectChainData.CHAIN_FONT, 'Inter', 'Sans Serif'].join(', '),
  titleBig: {
    fontSize: pxToRem(31),
    fontWeight: 600,
    lineHeight: pxToRem(40),
  },
  titleRegular: {
    fontSize: pxToRem(22),
    fontWeight: 600,
    lineHeight: pxToRem(28),
  },
  subtitleBig: {
    fontSize: pxToRem(16),
    fontWeight: 600,
    lineHeight: pxToRem(24),
  },
  subtitleRegular: {
    fontSize: pxToRem(14),
    fontWeight: 600,
    lineHeight: pxToRem(20),
  },
  bodyBig: {
    fontSize: pxToRem(16),
    fontWeight: 400,
    lineHeight: pxToRem(24),
  },
  bodyRegular: {
    fontSize: pxToRem(14),
    fontWeight: 400,
    lineHeight: pxToRem(20),
  },
  buttonBig: {
    fontSize: pxToRem(16),
    fontWeight: 500,
    lineHeight: pxToRem(24),
  },
  buttonRegular: {
    fontSize: pxToRem(14),
    fontWeight: 550,
    lineHeight: pxToRem(20),
  },
  monoBig: {
    fontSize: pxToRem(16),
    fontWeight: 400,
    lineHeight: pxToRem(24),
  },
  monoRegular: {
    fontSize: pxToRem(14),
    fontWeight: 400,
    lineHeight: pxToRem(20),
  },
} as const
