import { ProjectChainData } from '@/data/ProjectChainData'
import React from 'react'

export default function MonitorSCIcon() {
  return (
    <svg viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M47.5 22.5H40.55C37.7025 22.5 36.2788 22.5 35.1951 21.9371C34.2819 21.4627 33.5373 20.7181 33.0629 19.8049C32.5 18.7212 32.5 17.2975 32.5 14.45V7.5M47.5 22.5V44.45C47.5 47.2975 47.5 48.7212 46.9371 49.8049C46.4627 50.7181 45.7181 51.4627 44.8049 51.9371C43.7212 52.5 42.2975 52.5 39.45 52.5H20.55C17.7025 52.5 16.2788 52.5 15.1951 51.9371C14.2819 51.4627 13.5373 50.7181 13.0629 49.8049C12.5 48.7212 12.5 47.2975 12.5 44.45V15.55C12.5 12.7025 12.5 11.2788 13.0629 10.1951C13.5373 9.28191 14.2819 8.53731 15.1951 8.06293C16.2788 7.5 17.7025 7.5 20.55 7.5H32.5M47.5 22.5L32.5 7.5"
        stroke={ProjectChainData.CHAIN_PRIMARY_COLOR}
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 32.5H37.5"
        stroke={ProjectChainData.CHAIN_PRIMARY_COLOR}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 42.5H32.5"
        stroke={ProjectChainData.CHAIN_PRIMARY_COLOR}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
