import createEmotionCache from '@/config/createEmotionCache'
import theme from '@/config/theme'
import { AuthProvider } from '@/contexts/AuthContext'

import '@/styles/globals.css'
import { CacheProvider, EmotionCache } from '@emotion/react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { SnackBarProvider } from '@/contexts/SnackbarContext'
import { ProjectChainData } from '@/data/ProjectChainData'

const clientSideEmotionCache = createEmotionCache()

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
}

export default function App(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>{ProjectChainData.CHAIN_TITLE_NAME}</title>
        <link rel="manifest" href="/manifest.json" />
        <link rel="icon" href={ProjectChainData.CHAIN_FAVICON} sizes="any" />
        <meta name="theme-color" content="#0f0f0f" />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
      </Head>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <SnackBarProvider>
            <CssBaseline />
            <Component {...pageProps} />
          </SnackBarProvider>
        </ThemeProvider>
      </AuthProvider>
    </CacheProvider>
  )
}
