/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: api.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as api_pb from './api_pb'; // proto import: "api.proto"


export class GatewayPublicClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetJwt = new grpcWeb.MethodDescriptor(
    '/api.GatewayPublic/GetJwt',
    grpcWeb.MethodType.UNARY,
    api_pb.GetJwtRequest,
    api_pb.GetJwtResponse,
    (request: api_pb.GetJwtRequest) => {
      return request.serializeBinary();
    },
    api_pb.GetJwtResponse.deserializeBinary
  );

  getJwt(
    request: api_pb.GetJwtRequest,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.GetJwtResponse>;

  getJwt(
    request: api_pb.GetJwtRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.GetJwtResponse) => void): grpcWeb.ClientReadableStream<api_pb.GetJwtResponse>;

  getJwt(
    request: api_pb.GetJwtRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.GetJwtResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.GatewayPublic/GetJwt',
        request,
        metadata || {},
        this.methodDescriptorGetJwt,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.GatewayPublic/GetJwt',
    request,
    metadata || {},
    this.methodDescriptorGetJwt);
  }

}

export class GatewayAdminClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateChain = new grpcWeb.MethodDescriptor(
    '/api.GatewayAdmin/CreateChain',
    grpcWeb.MethodType.UNARY,
    api_pb.CreateChainRequest,
    api_pb.CreateChainResponse,
    (request: api_pb.CreateChainRequest) => {
      return request.serializeBinary();
    },
    api_pb.CreateChainResponse.deserializeBinary
  );

  createChain(
    request: api_pb.CreateChainRequest,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.CreateChainResponse>;

  createChain(
    request: api_pb.CreateChainRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.CreateChainResponse) => void): grpcWeb.ClientReadableStream<api_pb.CreateChainResponse>;

  createChain(
    request: api_pb.CreateChainRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.CreateChainResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.GatewayAdmin/CreateChain',
        request,
        metadata || {},
        this.methodDescriptorCreateChain,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.GatewayAdmin/CreateChain',
    request,
    metadata || {},
    this.methodDescriptorCreateChain);
  }

  methodDescriptorUpdateChain = new grpcWeb.MethodDescriptor(
    '/api.GatewayAdmin/UpdateChain',
    grpcWeb.MethodType.UNARY,
    api_pb.UpdateChainRequest,
    api_pb.UpdateChainResponse,
    (request: api_pb.UpdateChainRequest) => {
      return request.serializeBinary();
    },
    api_pb.UpdateChainResponse.deserializeBinary
  );

  updateChain(
    request: api_pb.UpdateChainRequest,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.UpdateChainResponse>;

  updateChain(
    request: api_pb.UpdateChainRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.UpdateChainResponse) => void): grpcWeb.ClientReadableStream<api_pb.UpdateChainResponse>;

  updateChain(
    request: api_pb.UpdateChainRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.UpdateChainResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.GatewayAdmin/UpdateChain',
        request,
        metadata || {},
        this.methodDescriptorUpdateChain,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.GatewayAdmin/UpdateChain',
    request,
    metadata || {},
    this.methodDescriptorUpdateChain);
  }

  methodDescriptorSendBroadcast = new grpcWeb.MethodDescriptor(
    '/api.GatewayAdmin/SendBroadcast',
    grpcWeb.MethodType.UNARY,
    api_pb.SendBroadcastRequest,
    api_pb.SendBroadcastResponse,
    (request: api_pb.SendBroadcastRequest) => {
      return request.serializeBinary();
    },
    api_pb.SendBroadcastResponse.deserializeBinary
  );

  sendBroadcast(
    request: api_pb.SendBroadcastRequest,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.SendBroadcastResponse>;

  sendBroadcast(
    request: api_pb.SendBroadcastRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.SendBroadcastResponse) => void): grpcWeb.ClientReadableStream<api_pb.SendBroadcastResponse>;

  sendBroadcast(
    request: api_pb.SendBroadcastRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.SendBroadcastResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.GatewayAdmin/SendBroadcast',
        request,
        metadata || {},
        this.methodDescriptorSendBroadcast,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.GatewayAdmin/SendBroadcast',
    request,
    metadata || {},
    this.methodDescriptorSendBroadcast);
  }

}

export class GatewayClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorInitiateDestinationRegistration = new grpcWeb.MethodDescriptor(
    '/api.Gateway/InitiateDestinationRegistration',
    grpcWeb.MethodType.UNARY,
    api_pb.InitiateDestinationRegistrationRequest,
    api_pb.InitiateDestinationRegistrationResponse,
    (request: api_pb.InitiateDestinationRegistrationRequest) => {
      return request.serializeBinary();
    },
    api_pb.InitiateDestinationRegistrationResponse.deserializeBinary
  );

  initiateDestinationRegistration(
    request: api_pb.InitiateDestinationRegistrationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.InitiateDestinationRegistrationResponse>;

  initiateDestinationRegistration(
    request: api_pb.InitiateDestinationRegistrationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.InitiateDestinationRegistrationResponse) => void): grpcWeb.ClientReadableStream<api_pb.InitiateDestinationRegistrationResponse>;

  initiateDestinationRegistration(
    request: api_pb.InitiateDestinationRegistrationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.InitiateDestinationRegistrationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.Gateway/InitiateDestinationRegistration',
        request,
        metadata || {},
        this.methodDescriptorInitiateDestinationRegistration,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.Gateway/InitiateDestinationRegistration',
    request,
    metadata || {},
    this.methodDescriptorInitiateDestinationRegistration);
  }

  methodDescriptorConfirmDestinationRegistration = new grpcWeb.MethodDescriptor(
    '/api.Gateway/ConfirmDestinationRegistration',
    grpcWeb.MethodType.UNARY,
    api_pb.ConfirmDestinationRegistrationRequest,
    api_pb.ConfirmDestinationRegistrationResponse,
    (request: api_pb.ConfirmDestinationRegistrationRequest) => {
      return request.serializeBinary();
    },
    api_pb.ConfirmDestinationRegistrationResponse.deserializeBinary
  );

  confirmDestinationRegistration(
    request: api_pb.ConfirmDestinationRegistrationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.ConfirmDestinationRegistrationResponse>;

  confirmDestinationRegistration(
    request: api_pb.ConfirmDestinationRegistrationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.ConfirmDestinationRegistrationResponse) => void): grpcWeb.ClientReadableStream<api_pb.ConfirmDestinationRegistrationResponse>;

  confirmDestinationRegistration(
    request: api_pb.ConfirmDestinationRegistrationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.ConfirmDestinationRegistrationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.Gateway/ConfirmDestinationRegistration',
        request,
        metadata || {},
        this.methodDescriptorConfirmDestinationRegistration,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.Gateway/ConfirmDestinationRegistration',
    request,
    metadata || {},
    this.methodDescriptorConfirmDestinationRegistration);
  }

  methodDescriptorGetUserDestinations = new grpcWeb.MethodDescriptor(
    '/api.Gateway/GetUserDestinations',
    grpcWeb.MethodType.UNARY,
    api_pb.GetUserDestinationsRequest,
    api_pb.GetUserDestinationsResponse,
    (request: api_pb.GetUserDestinationsRequest) => {
      return request.serializeBinary();
    },
    api_pb.GetUserDestinationsResponse.deserializeBinary
  );

  getUserDestinations(
    request: api_pb.GetUserDestinationsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.GetUserDestinationsResponse>;

  getUserDestinations(
    request: api_pb.GetUserDestinationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.GetUserDestinationsResponse) => void): grpcWeb.ClientReadableStream<api_pb.GetUserDestinationsResponse>;

  getUserDestinations(
    request: api_pb.GetUserDestinationsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.GetUserDestinationsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.Gateway/GetUserDestinations',
        request,
        metadata || {},
        this.methodDescriptorGetUserDestinations,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.Gateway/GetUserDestinations',
    request,
    metadata || {},
    this.methodDescriptorGetUserDestinations);
  }

  methodDescriptorUpdateUserDestination = new grpcWeb.MethodDescriptor(
    '/api.Gateway/UpdateUserDestination',
    grpcWeb.MethodType.UNARY,
    api_pb.UpdateUserDestinationRequest,
    api_pb.UpdateUserDestinationResponse,
    (request: api_pb.UpdateUserDestinationRequest) => {
      return request.serializeBinary();
    },
    api_pb.UpdateUserDestinationResponse.deserializeBinary
  );

  updateUserDestination(
    request: api_pb.UpdateUserDestinationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.UpdateUserDestinationResponse>;

  updateUserDestination(
    request: api_pb.UpdateUserDestinationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.UpdateUserDestinationResponse) => void): grpcWeb.ClientReadableStream<api_pb.UpdateUserDestinationResponse>;

  updateUserDestination(
    request: api_pb.UpdateUserDestinationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.UpdateUserDestinationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.Gateway/UpdateUserDestination',
        request,
        metadata || {},
        this.methodDescriptorUpdateUserDestination,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.Gateway/UpdateUserDestination',
    request,
    metadata || {},
    this.methodDescriptorUpdateUserDestination);
  }

  methodDescriptorDeleteUserDestination = new grpcWeb.MethodDescriptor(
    '/api.Gateway/DeleteUserDestination',
    grpcWeb.MethodType.UNARY,
    api_pb.DeleteUserDestinationRequest,
    api_pb.DeleteUserDestinationResponse,
    (request: api_pb.DeleteUserDestinationRequest) => {
      return request.serializeBinary();
    },
    api_pb.DeleteUserDestinationResponse.deserializeBinary
  );

  deleteUserDestination(
    request: api_pb.DeleteUserDestinationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.DeleteUserDestinationResponse>;

  deleteUserDestination(
    request: api_pb.DeleteUserDestinationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.DeleteUserDestinationResponse) => void): grpcWeb.ClientReadableStream<api_pb.DeleteUserDestinationResponse>;

  deleteUserDestination(
    request: api_pb.DeleteUserDestinationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.DeleteUserDestinationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.Gateway/DeleteUserDestination',
        request,
        metadata || {},
        this.methodDescriptorDeleteUserDestination,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.Gateway/DeleteUserDestination',
    request,
    metadata || {},
    this.methodDescriptorDeleteUserDestination);
  }

  methodDescriptorCreateAlert = new grpcWeb.MethodDescriptor(
    '/api.Gateway/CreateAlert',
    grpcWeb.MethodType.UNARY,
    api_pb.CreateAlertRequest,
    api_pb.CreateAlertResponse,
    (request: api_pb.CreateAlertRequest) => {
      return request.serializeBinary();
    },
    api_pb.CreateAlertResponse.deserializeBinary
  );

  createAlert(
    request: api_pb.CreateAlertRequest,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.CreateAlertResponse>;

  createAlert(
    request: api_pb.CreateAlertRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.CreateAlertResponse) => void): grpcWeb.ClientReadableStream<api_pb.CreateAlertResponse>;

  createAlert(
    request: api_pb.CreateAlertRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.CreateAlertResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.Gateway/CreateAlert',
        request,
        metadata || {},
        this.methodDescriptorCreateAlert,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.Gateway/CreateAlert',
    request,
    metadata || {},
    this.methodDescriptorCreateAlert);
  }

  methodDescriptorGetAlerts = new grpcWeb.MethodDescriptor(
    '/api.Gateway/GetAlerts',
    grpcWeb.MethodType.UNARY,
    api_pb.GetAlertsRequest,
    api_pb.GetAlertsResponse,
    (request: api_pb.GetAlertsRequest) => {
      return request.serializeBinary();
    },
    api_pb.GetAlertsResponse.deserializeBinary
  );

  getAlerts(
    request: api_pb.GetAlertsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.GetAlertsResponse>;

  getAlerts(
    request: api_pb.GetAlertsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.GetAlertsResponse) => void): grpcWeb.ClientReadableStream<api_pb.GetAlertsResponse>;

  getAlerts(
    request: api_pb.GetAlertsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.GetAlertsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.Gateway/GetAlerts',
        request,
        metadata || {},
        this.methodDescriptorGetAlerts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.Gateway/GetAlerts',
    request,
    metadata || {},
    this.methodDescriptorGetAlerts);
  }

  methodDescriptorUpdateAlert = new grpcWeb.MethodDescriptor(
    '/api.Gateway/UpdateAlert',
    grpcWeb.MethodType.UNARY,
    api_pb.UpdateAlertRequest,
    api_pb.UpdateAlertResponse,
    (request: api_pb.UpdateAlertRequest) => {
      return request.serializeBinary();
    },
    api_pb.UpdateAlertResponse.deserializeBinary
  );

  updateAlert(
    request: api_pb.UpdateAlertRequest,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.UpdateAlertResponse>;

  updateAlert(
    request: api_pb.UpdateAlertRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.UpdateAlertResponse) => void): grpcWeb.ClientReadableStream<api_pb.UpdateAlertResponse>;

  updateAlert(
    request: api_pb.UpdateAlertRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.UpdateAlertResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.Gateway/UpdateAlert',
        request,
        metadata || {},
        this.methodDescriptorUpdateAlert,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.Gateway/UpdateAlert',
    request,
    metadata || {},
    this.methodDescriptorUpdateAlert);
  }

  methodDescriptorDeleteAlert = new grpcWeb.MethodDescriptor(
    '/api.Gateway/DeleteAlert',
    grpcWeb.MethodType.UNARY,
    api_pb.DeleteAlertRequest,
    api_pb.DeleteAlertResponse,
    (request: api_pb.DeleteAlertRequest) => {
      return request.serializeBinary();
    },
    api_pb.DeleteAlertResponse.deserializeBinary
  );

  deleteAlert(
    request: api_pb.DeleteAlertRequest,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.DeleteAlertResponse>;

  deleteAlert(
    request: api_pb.DeleteAlertRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.DeleteAlertResponse) => void): grpcWeb.ClientReadableStream<api_pb.DeleteAlertResponse>;

  deleteAlert(
    request: api_pb.DeleteAlertRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.DeleteAlertResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.Gateway/DeleteAlert',
        request,
        metadata || {},
        this.methodDescriptorDeleteAlert,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.Gateway/DeleteAlert',
    request,
    metadata || {},
    this.methodDescriptorDeleteAlert);
  }

  methodDescriptorGetNotifications = new grpcWeb.MethodDescriptor(
    '/api.Gateway/GetNotifications',
    grpcWeb.MethodType.UNARY,
    api_pb.GetNotificationsRequest,
    api_pb.GetNotificationsResponse,
    (request: api_pb.GetNotificationsRequest) => {
      return request.serializeBinary();
    },
    api_pb.GetNotificationsResponse.deserializeBinary
  );

  getNotifications(
    request: api_pb.GetNotificationsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.GetNotificationsResponse>;

  getNotifications(
    request: api_pb.GetNotificationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.GetNotificationsResponse) => void): grpcWeb.ClientReadableStream<api_pb.GetNotificationsResponse>;

  getNotifications(
    request: api_pb.GetNotificationsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.GetNotificationsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.Gateway/GetNotifications',
        request,
        metadata || {},
        this.methodDescriptorGetNotifications,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.Gateway/GetNotifications',
    request,
    metadata || {},
    this.methodDescriptorGetNotifications);
  }

  methodDescriptorGetStatistics = new grpcWeb.MethodDescriptor(
    '/api.Gateway/GetStatistics',
    grpcWeb.MethodType.UNARY,
    api_pb.GetStatisticsRequest,
    api_pb.GetStatisticsResponse,
    (request: api_pb.GetStatisticsRequest) => {
      return request.serializeBinary();
    },
    api_pb.GetStatisticsResponse.deserializeBinary
  );

  getStatistics(
    request: api_pb.GetStatisticsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.GetStatisticsResponse>;

  getStatistics(
    request: api_pb.GetStatisticsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.GetStatisticsResponse) => void): grpcWeb.ClientReadableStream<api_pb.GetStatisticsResponse>;

  getStatistics(
    request: api_pb.GetStatisticsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.GetStatisticsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.Gateway/GetStatistics',
        request,
        metadata || {},
        this.methodDescriptorGetStatistics,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.Gateway/GetStatistics',
    request,
    metadata || {},
    this.methodDescriptorGetStatistics);
  }

  methodDescriptorGetChains = new grpcWeb.MethodDescriptor(
    '/api.Gateway/GetChains',
    grpcWeb.MethodType.UNARY,
    api_pb.GetChainsRequest,
    api_pb.GetChainsResponse,
    (request: api_pb.GetChainsRequest) => {
      return request.serializeBinary();
    },
    api_pb.GetChainsResponse.deserializeBinary
  );

  getChains(
    request: api_pb.GetChainsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<api_pb.GetChainsResponse>;

  getChains(
    request: api_pb.GetChainsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: api_pb.GetChainsResponse) => void): grpcWeb.ClientReadableStream<api_pb.GetChainsResponse>;

  getChains(
    request: api_pb.GetChainsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: api_pb.GetChainsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.Gateway/GetChains',
        request,
        metadata || {},
        this.methodDescriptorGetChains,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.Gateway/GetChains',
    request,
    metadata || {},
    this.methodDescriptorGetChains);
  }

}

