import { ProjectChainData } from '@/data/ProjectChainData'
import { Box, CircularProgress } from '@mui/material'
import React from 'react'

export default function LoadingScreen() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        margin: 'auto',
      }}
    >
      <CircularProgress
        sx={{
          color: ProjectChainData.CHAIN_PRIMARY_COLOR,
        }}
      />
    </Box>
  )
}
