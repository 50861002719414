import { ProjectChainData } from '@/data/ProjectChainData'
import React from 'react'

export default function WalletIcon() {
  return (
    <svg viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 15C11.25 12.9289 12.9289 11.25 15 11.25H45V8.75H15C11.5482 8.75 8.75 11.5482 8.75 15V42.5C8.75 45.9518 11.5482 48.75 15 48.75H45C48.4518 48.75 51.25 45.9518 51.25 42.5V25C51.25 21.5482 48.4518 18.75 45 18.75H15C12.9289 18.75 11.25 17.0711 11.25 15ZM11.25 42.5V20.0005C12.2946 20.7851 13.593 21.25 15 21.25H45C47.0711 21.25 48.75 22.9289 48.75 25V42.5C48.75 44.5711 47.0711 46.25 45 46.25H15C12.9289 46.25 11.25 44.5711 11.25 42.5ZM41.25 36.75C42.9069 36.75 44.25 35.4069 44.25 33.75C44.25 32.0931 42.9069 30.75 41.25 30.75C39.5931 30.75 38.25 32.0931 38.25 33.75C38.25 35.4069 39.5931 36.75 41.25 36.75Z"
        fill={ProjectChainData.CHAIN_PRIMARY_COLOR}
      />
    </svg>
  )
}
